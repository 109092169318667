import { createTheme } from '@mui/material/styles';
import { Colors } from './colors';
import { NeutralColors, PrimaryColors, GrayscaleColors, SecondaryColors } from './spcolors';
export const theme = createTheme({
    inter: {
        fontFamily: "'Inter'",
        h1: { fontSize: 36, fontWeight: 700, lineHeight: 1.5 },
        h2: { fontSize: 28, fontWeight: 700, lineHeight: 1.5 },
        h3: { fontSize: 20, fontWeight: 700, lineHeight: 1.5 },
        h4: { fontSize: 16, fontWeight: 700, lineHeight: 1.5 },
        h5: { fontSize: 14, fontWeight: 700, lineHeight: 1.5 },
        h6: { fontSize: 12, fontWeight: 700, lineHeight: 1.5 },
        h7: { fontSize: 10, fontWeight: 700, lineHeight: 1.5 },
        sh1: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh2: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh3: {
            fontSize: 18,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh4: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh5: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh6: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh7: {
            fontSize: 10,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh8: {
            fontSize: 8,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        b1: { fontSize: 24, fontWeight: 400, lineHeight: 1.5 },
        b2: { fontSize: 20, fontWeight: 400, lineHeight: 1.5 },
        b3: { fontSize: 18, fontWeight: 400, lineHeight: 1.5 },
        b4: { fontSize: 16, fontWeight: 400, lineHeight: 1.5 },
        b5: { fontSize: 14, fontWeight: 400, lineHeight: 1.5 },
        b6: { fontSize: 12, fontWeight: 400, lineHeight: 1.5 },
        b7: { fontSize: 10, fontWeight: 400, lineHeight: 1.5 },
    },
    ginto: {
        fontFamily: "'GintoNord'",
        h1: { fontSize: 40, fontWeight: 700, lineHeight: 1.5 },
        h2: { fontSize: 32, fontWeight: 700, lineHeight: 1.5 },
        h3: { fontSize: 24, fontWeight: 700, lineHeight: 1.5 },
        h4: { fontSize: 18, fontWeight: 700, lineHeight: 1.5 },
        h5: { fontSize: 14, fontWeight: 700, lineHeight: 1.5 },
        h6: { fontSize: 12, fontWeight: 700, lineHeight: 1.5 },
        h7: { fontSize: 10, fontWeight: 700, lineHeight: 1.5 },
        sh1: {
            fontSize: 40,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh2: {
            fontSize: 32,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh3: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh4: {
            fontSize: 18,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh5: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh6: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh7: {
            fontSize: 10,
            fontWeight: 500,
            lineHeight: 1.5,
        },
    },
    typography: {
        fontFamily: "'Inter'",
        h1: { fontSize: 36, fontWeight: 700, lineHeight: 1.5 },
        h2: { fontSize: 28, fontWeight: 700, lineHeight: 1.5 },
        h3: { fontSize: 20, fontWeight: 700, lineHeight: 1.5 },
        h4: { fontSize: 16, fontWeight: 700, lineHeight: 1.5 },
        h5: { fontSize: 14, fontWeight: 700, lineHeight: 1.5 },
        h6: { fontSize: 12, fontWeight: 700, lineHeight: 1.5 },
        h7: { fontSize: 10, fontWeight: 700, lineHeight: 1.5 },
        h8: { fontSize: 8, fontWeight: 700, lineHeight: 1.5 },
        sh1: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh2: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh3: {
            fontSize: 18,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh4: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh5: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh6: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh7: {
            fontSize: 10,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        sh8: {
            fontSize: 8,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        b1: { fontSize: 24, fontWeight: 400, lineHeight: 1.5 },
        b2: { fontSize: 20, fontWeight: 400, lineHeight: 1.5 },
        b3: { fontSize: 18, fontWeight: 400, lineHeight: 1.5 },
        b4: { fontSize: 16, fontWeight: 400, lineHeight: 1.5 },
        b5: { fontSize: 14, fontWeight: 400, lineHeight: 1.5 },
        b6: { fontSize: 12, fontWeight: 400, lineHeight: 1.5 },
        b7: { fontSize: 10, fontWeight: 400, lineHeight: 1.5 },
        b8: { fontSize: 8, fontWeight: 400, lineHeight: 1.5 },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            mobile: 0,
            tablet: 600,
            desktop: 1000,
            desktopL: 1200,
        },
    },
    components: {
        MuiInputBase: {
            defaultProps: {
                disableInjectingGlobalStyles: true,
            },
        },
        MuiDivider: {
            styleOverrides: {
                light: {
                    borderColor: GrayscaleColors.GrayLight02,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: GrayscaleColors.GrayDark03,
                    border: `1px solid ${GrayscaleColors.GrayDark03}`,
                    '&.Mui-selected, &.Mui-selected:hover': {
                        color: GrayscaleColors.White,
                        background: GrayscaleColors.GrayDark03,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 700,
                    lineHeight: 1.5,
                    padding: '8px 24px',
                },
                containedPrimary: {
                    color: GrayscaleColors.GrayDark03,
                    '&.Mui-disabled': {
                        backgroundColor: GrayscaleColors.GrayBase,
                    },
                },
                outlinedPrimary: {
                    color: GrayscaleColors.GrayDark03,
                    borderColor: GrayscaleColors.GrayDark03,
                    '&:hover': {
                        borderColor: GrayscaleColors.GrayDark02,
                    },
                },
                outlinedSecondary: {
                    background: GrayscaleColors.White,
                    borderColor: PrimaryColors.PurpleDark03,
                    '&:hover': {
                        background: Colors.lightBlue5,
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'dark' },
                    style: {
                        color: GrayscaleColors.White,
                        background: GrayscaleColors.GrayDark03,
                        '&:hover': {
                            background: GrayscaleColors.GrayDark02,
                        },
                    },
                },
            ],
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: GrayscaleColors.GrayDark03,
                },
                rail: {
                    background: GrayscaleColors.GrayLight01,
                },
                track: {
                    background: GrayscaleColors.GrayDark03,
                },
                thumb: {
                    '&.Mui-focusVisible, &:hover': {
                        boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, 0.16)',
                    },
                    '&.MuiSlider-active': {
                        boxShadow: '0px 0px 0px 14px rgba(0, 0, 0, 0.16)',
                    },
                },
                valueLabel: {
                    background: GrayscaleColors.GrayDark03,
                    color: '#fff',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: GrayscaleColors.GrayDark03,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.06)',
                },
            },
        },
    },
    palette: {
        error: { main: Colors.error },
        primary: {
            ...PrimaryColors,
            main: PrimaryColors.PurpleBase,
        },
        secondary: {
            ...SecondaryColors,
            main: SecondaryColors.BlueDark03,
            40: Colors.darkBlue40,
            20: Colors.darkBlue20,
            5: Colors.darkBlue5,
            3: Colors.darkBlue3,
        },
        gray: GrayscaleColors,
        neutral: NeutralColors,
        tertiary: {
            main: Colors.lightBlue100,
            40: Colors.lightBlue40,
            20: Colors.lightBlue20,
        },
    },
});
